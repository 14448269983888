<template>
  <div>
    <v-card width="100%" class="text-center" color="#fff">
      <v-img contain max-height="300px" src="/educaaws.jpeg"></v-img>
      <v-btn
        :to="{ name: 'CursoForm' }"
        v-if="me.role.name === 'admin'"
        color="primary"
        class="mb-4"
      >
        Cadastrar novo curso
      </v-btn>
      <v-btn
        :to="{ name: 'VideoForm' }"
        v-if="me.role.name === 'admin'"
        color="primary"
        class="mb-4 ml-2"
      >
        Cadastrar novo video
      </v-btn>
    </v-card>

    <v-container class="lighten-3" fluid text-center>
      <v-row class="mt-4">
        <v-col
          v-for="curso in dataTable"
          :key="curso.id"
          v-show="curso.private == privateValue"
          cols="12"
          md="6"
        >
          <v-card style="height: 100%;" :color="'#014486'" dark>
            <div class="d-flex flex-no-wrap justify-space-between text-center">
              <div style="text-align: center;">
                <v-card-title
                  class="text-h6 mx-auto text-left text-break"
                  style="text-wrap: balance;overflow-wrap: break-word; width: 100%; font-size: 1rem !important;"
                  v-text="curso.titulo"
                ></v-card-title>

                <v-card-subtitle
                  class="text-left text-start"
                  v-text="'Autor: Wellington Sampaio'"
                ></v-card-subtitle>

                <v-card-text
                  class="text-left text-justify text-break"
                  v-text="curso.descricao"
                ></v-card-text>

                <v-card-actions>
                  <v-btn
                  v-if="curso.videos.length > 0"
                    :to="{ path: '/app/academy/videos/' + curso.id }"
                    class="ml-2 mt-5"
                    outlined
                    rounded
                    small
                  >
                    Ver Vídeos
                  </v-btn>
                  <v-btn
                  
                  v-if="curso.pdf && curso.pdf.path" 
                    :href="'https://wsjus.com.br/storage/public/' + curso.pdf.path"
                    class="ml-2 mt-5"
                    target="_blank"
                    outlined
                    rounded
                    small
                  >
                    Ler PDF
                  </v-btn>
                  <v-btn
                    :to="{ name: 'CursoFormEdit', params: { id: curso.id } }"
                    v-if="me.role.name === 'admin'"
                    class="ml-2 mt-5"
                    outlined
                    rounded
                    small
                  >
                    Editar Curso
                  </v-btn>
                  <v-btn
                    @click="() => deleteCurso(curso.id)"
                    v-if="me.role.name === 'admin'"
                    class="ml-2 mt-5"
                    outlined
                    rounded
                    small
                  >
                    Excluir
                  </v-btn>
                </v-card-actions>
              </div>

              <v-avatar class="ma-3" size="125" tile>
                <v-img :src="url + curso.image.path"></v-img>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ToastService from "@/services/ToastService";
import moment from "moment";
import instanceAxios from "../../plugins/axios";
import { mapGetters } from "vuex";
export default {
  created: async function() {
    this.indexData();
    // this.indexVideos();
  },

  props: {
    privateValue: {
      default: false,
      type: Boolean,
      required: false,
    },
  },

  components: {},
  computed: {
    ...mapGetters({
      me: "getMe",
    }),
    url: {
      get: () => {
        return process.env.VUE_APP_HOST_STORAGE;
      },
    },
  },
  data: () => ({
    dataTable: [],
    videos: [],
  }),

  filters: {
    formatDate: function(el) {
      return moment(el).format("DD/MM/YYYY");
    },
  },

  methods: {
    async indexData() {
      try {
        this.loading = true;
        const url = this.private ? "?private=true" : "";
        const res = await instanceAxios.get("cursos" + url);
        this.dataTable = res.data;
      } catch (exception) {
        ToastService(exception, "error");
      } finally {
        this.loading = false;
      }
    },

    async indexVideos() {
      try {
        const url = this.privateValue ? "?private=true" : "";
        const res = await instanceAxios.get(`cursos/0` + url);
        this.videos = res.data.videos;
      } catch (exception) {
        ToastService(exception, "error");
      } finally {
        //
      }
    },

    async deleteCurso(id) {
      try {
        await instanceAxios.delete(`cursos/${id}`);

        ToastService("Deletado com sucesso", "success");

        this.indexData();
      } catch (error) {
        ToastService("Não foi possível excluir", "error");
      }
    },

    async deleteVideo(id) {
      try {
        await instanceAxios.delete(`videos/${id}`);

        ToastService("Deletado com sucesso", "success");

        this.indexVideos();
      } catch (error) {
        ToastService("Não foi possível excluir", "error");
      }
    },
  },
};
</script>

<style></style>
